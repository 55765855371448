import charcoal_serika from './img/charcoal_serika.jpeg'
import charcoal_bottom from './img/charcoal_bottom.jpeg'
import charcoal_serika_side from './img/charcoal_serika_side.jpeg'
import sand_pwork from './img/sand_pwork.jpeg'
import sand_pwork_side from './img/sand_pwork_side.jpeg'
import sand_muted_logo from './img/sand_muted_logo.jpg'
import sand_muted_angle from './img/sand_muted_angle.jpg'
import sand_muted_back from './img/sand_muted_back.jpg'

const VanagonR4Page = [
  {
    type: 'header',
    value: 'V4N4G0N R4 Hall Effect MX Hybrid $375'
  },
  {
    type: 'updates',
    updates: [
      {date: '3-12-2025', content: `PCBs and plates were received today. Tariffs were higher than expected.`},
      {date: '3-6-2025', content: `PCBs and plates have shipped and are enroute to me with an expected delivery of March 11th 2025.`},
      {date: '2-19-2025', content: `All manufacturing orders have been placed and paid for. I already have the tadpoles, usb c daughterboards and rubber case feet on hand. The case production timeline came in shorter than expected which means these kits could potentially ship sooner. Will update if there is a material change in delivery timeframe. PCBs are complete and have begun assembly.`},
      {date: '2-1-2025', content: 'Group buy is closed. Order button has been disabled and the extra pcb form has been closed. Invoices have been sent out for pcb extras. I am tallying up everything and will be placing manufacturing orders in the next couple days. A total of 72 kits were ordered, 50 of them were charcoal.'}
    ]
  },
  {
    type: 'text',
    value: 'V4N4G0N R4 features the classic case design from R1/R2 with many quality of life improvements. The plate is now tadpole mount. There are alignment pins on the case making assembly a breeze. The case screws have been reduced to just 4. An S1 daughterboard connects the pcb to the computer. The new PCB supports both MX and Hall Effect switches and ships with Vial firmware.'
  },
  {
    type: 'image',
    value: charcoal_serika,
    md: 5,
    mdOffset: 2
  },
  {
    type: 'image',
    value: sand_muted_logo,
    md: 3,
  },
  {
    type: 'text',
    value: 'These kits are expected to take about 5 months to produce. Each kit contains the following items.'
  },
  {
    type: 'list',
    values: [
      'Charcoal or Sand anodized aluminum case',
      'Sealed Brass Mid Layer',
      '(4) Silver M3x10 screws',
      '(4) Rubber Feet',
      '(6) Tadpoles',
      'Full Space Layout FR4 Plate',
      'V4N4G0N Hybrid Hotswap PCB',
      'S1 Daughterboard, 2 screws and cable',
    ]
  },
  {
    type: 'image',
    value: charcoal_serika_side,
    lg: 2,
    md: 4
  },
  {
    type: 'image',
    value: charcoal_bottom,
    lg: 4,
    md: 8
  },
  {
    type: 'image',
    value: sand_pwork,
    md: 8,
    lg: 4
  },
  {
    type: 'image',
    value: sand_pwork_side,
    lg: 2,
    md: 4
  },
  {
    type: 'image',
    value: sand_muted_angle,
    lg: 6,
    md: 6
  },
  {
    type: 'image',
    value: sand_muted_back,
    lg: 6,
    md: 6
  },
]

export default VanagonR4Page;

